import 'src/styles/pages/politica-de-privacidad.scss'
import Newsletter from 'src/components/sections/Newsletter'
import StockAvailable from 'src/components/common/StockAvailable/StockAvailable'

function Page() {
  return (
    <section className="privacy-container">
      <div className="title-container">
        <h2 className="title-text">Política de privacidad</h2>
      </div>

      <div className="content-container">
        <div className="imageDescargar-container">
          <a
            className="linkDescargar"
            href="https://tatauy.vteximg.com.br/arquivos/politicadeprivacidad.pdf?v=637763841167130000"
          >
            <img
              className="imageDescargar"
              src="https://tatauyqa.vteximg.com.br/arquivos/descargar3.png"
              alt="imagen-descargar"
            />
          </a>
        </div>
        <p className="first-paragraph">
          Al registrarse y utilizar los servicios de Tata.com.uy, usted
          consiente y acepta que el tratamiento de sus datos personales se
          realice por TA TA .S.A. de acuerdo con lo informado en este documento
          y con lo dispuesto en la Ley Nº 18.331, de 11 de agosto de 2008 y el
          Decreto Nº 414/009, de 21 de agosto de 2009, modificativas y
          concordantes.
        </p>
        <br />
        <h2 className="title-topicOne">1. Tratamiento de datos. Finalidad.</h2>
        <p className="paragraphOne-topicOne">
          Los datos personales recabados y accedidos para el registro en nuestra
          base de datos, serán tratados por TA TA S.A. con la finalidad de
          contactar con el usuario, brindar la información solicitada, así como
          fines operativos del sistema de comercio electrónico del sitio, que
          podrán ser utilizados en otros sitios web propios de TA TA S.A.,
          aceptando recibir publicidad, anuncios y promociones por los distintos
          medios posibles de TA TA S.A. o cualquiera de sus empresas vinculadas,
          controlantes o controladas. También se podrán tratar los datos para
          cumplir con el giro comercial de la Empresa; otorgar beneficios;
          prestar servicios; realizar análisis y estudios, que podrán esta
          basados en el perfil de la persona, en la respuesta a sus encuestas,
          en los hábitos de compra, entre otros.
        </p>
        <p className="paragraphTwo-topicOne">
          Los datos personales se encuentran en bases inscriptas ante la Unidad
          Reguladora y de Control de Datos Personales, y cuentan con medidas de
          seguridad adecuadas que garantizan su integridad, disponibilidad y
          confidencialidad.
        </p>
        <br />
        <h2 className="title-topicTwo">2. Cookies</h2>
        <p className="paragraph-topicTwo">
          TA TA S.A. utiliza cookies propias, y podría utilizar en algún momento
          de terceros, para mejorar los servicios relacionados con sus
          preferencias, mediante el análisis de sus hábitos de navegación. Si
          continúa navegando, consideramos que acepta su uso. Usted puede
          configurar su navegador para ser avisado de la recepción de las
          cookies o impedir su instalación.
        </p>
        <br />
        <h2 className="title-topicThree">3. Ejercicio de los derechos</h2>
        <p className="paragraph-topicThree">
          Usted podrá ejercer sus derechos de acceso, rectificación,
          actualización, inclusión y supresión, ante TA TA S.A. a través del
          formulario de "Contacto", enviando un correo electrónico a Servicio de
          Atención al Cliente de TATA o al correo electrónico
          consultas.basededatos@tata.com.uy o personalmente dirigiéndose a José
          de Bejar 2600.
        </p>
        <br />
        <h2 className="title-topicFour">4. Comunicación de Datos</h2>
        <p className="paragraph-topicFour">
          Los datos personales recabados podrán ser comunicados a Entidades
          Públicas o a terceros (personas físicas o jurídicas), única y
          exclusivamente, cuando la consecución de la finalidad perseguida por
          el usuario así lo requiera, y a los solos efectos del cumplimiento de
          las prestaciones, quienes otorgarán con TA TA S.A. los respectivos
          contratos con cláusulas de confidencialidad y tutela de los Datos
          Personales de sus usuarios.
        </p>
        <br />
        <h2 className="title-topicFive">
          5. Transferencia internacional de datos
        </h2>
        <p className="paragraph-topicFive">
          Para el caso en que se realice una Transferencia Internacional de
          Datos, a un país que no cuente con un nivel de protección adecuado de
          acuerdo a los estándares de Derecho Internacional o regional en la
          materia, establecidos en la Resolución No.4/2019 de la U.R.C.D.P., los
          datos personales serán tratados únicamente de acuerdo a las
          instrucciones dadas por TA TA S.A., para lo cual se suscribirá los
          contratos con las garantías correspondientes y en particular las
          “cláusulas contractuales tipo” aprobadas por la Comisión Europea. En
          el siguiente mail ud. podrá solicitar más información al respecto y/o
          una copia de las cláusulas referidas{' '}
          <a
            className="emailLink"
            href="mailto:consultas.basededatos@tata.com.uy"
          >
            consultas.basededatos@tata.com.uy
          </a>
        </p>
        <br />
        <h2 className="title-topicSix">6. Enlaces</h2>
        <p className="paragraphOne-topicSix">
          La presente Política de Privacidad es de aplicación exclusiva para
          sitios, portales, servicios o contenidos de TA TA S.A.y no se extiende
          a los enlaces hacia otros sitios, portales, servicios o contenidos de
          distinta titularidad.
        </p>
        <p className="paragraphTwo-topicSix">
          Este sitio web puede incluir hipervínculos o enlaces que permitan
          acceder a páginas web de terceros, distintos a TA TA S.A.y que por lo
          tanto no son operados por esta.
        </p>
        <p className="paragraphThree-topicSix">
          Los titulares de dichos sitios web dispondrán de sus propias políticas
          de protección de datos personales, siendo ellos mismos los
          responsables de cada política de privacidad.
        </p>
        <br />
        <h2 className="title-topicSeven">
          7. Ley aplicable y jurisdicción competente
        </h2>
        <p className="paragraphOne-topicSeven">
          Esta Política se encuentra regida en todas sus cláusulas y sin
          excepción por las leyes de la República Oriental del Uruguay.
        </p>
        <p className="paragraphTwo-topicSeven">
          Cualquier controversia derivada de este documento relativa a su
          existencia, validez, interpretación, alcance o cumplimiento será
          sometida a los Tribunales ordinarios de la ciudad de Montevideo,
          Uruguay.
        </p>
      </div>

      <div className="newsletter-container">
        <Newsletter
          title="Recibí promociones y novedades"
          subtitle="Ingresá tu correo electrónico"
        />
      </div>

      <StockAvailable icon />
    </section>
  )
}

export default Page
